
import { notification, Modal, message } from 'ant-design-vue'
import { defineComponent, ref, onMounted, createVNode, reactive, watch, toRefs } from 'vue'
import { HomeOutlined, ArrowLeftOutlined, InboxOutlined, DeleteOutlined, ExclamationCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons-vue'

import profileService from '../../../services/profile'
import commonServices from '../../../services/common'
import courseService from '../../../services/course'

import ICourse from '../../../interface/course'

import router from '../../../router'

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: any;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

const state = reactive({
  indeterminate: false,
  selectAllCourse: false,
  selectedCourse: [],
  selectedGroup: []
})

export default defineComponent({
  components: {
    HomeOutlined,
    ArrowLeftOutlined,
    InboxOutlined,
    DeleteOutlined,
    CloudDownloadOutlined
  },
  setup () {
    const headers = ref<object>({
      token: localStorage.accessToken
    })
    const params = new URLSearchParams(window.location.search).get('loginAs')
    const loginAs = ref(params ? '?loginAs=' + params : '')
    const imageUploadUrl = process.env.VUE_APP_API_URL + process.env.VUE_APP_CSV_FILE_UPLOAD
    const clientId = ref<string>(commonServices.getCurrentProfile()._id)
    const candidateData = ref<Array<any>>([])
    const disableCreate = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const visible = ref<boolean>(false)
    const columns = [
      {
        title: 'First Name',
        dataIndex: 'first_name'
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name'
      },
      {
        title: 'Email',
        dataIndex: 'email'
      },
      {
        title: 'Phone',
        dataIndex: 'phone'
      },
      {
        title: 'Status',
        dataIndex: 'exist',
        slots: { customRender: 'exist' }
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ]
    const availableCourse = ref([])
    const unassignedCourses = ref<Array<ICourse>>([])
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const handleChange = (info: FileInfo) => {
      const status = info.file.status
      if (status !== 'uploading') {
        candidateData.value = info.file.response
        const error = candidateData.value.filter(data => data.exist === true)
        if (error.length > 0) {
          disableCreate.value = true
        }
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
    const getUnassignedCourses = async () => {
      try {
        availableCourse.value = []
        const clientId = commonServices.getClientId()
        const sCourse = await courseService.getClientCoursesByType(clientId, 'Single')
        const gCourse = await courseService.getClientCoursesByType(clientId, 'Group')

        unassignedCourses.value = [...sCourse.data, ...gCourse.data]

        for (const course of unassignedCourses.value) {
          availableCourse.value.push({ label: course.title, value: course._id })
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const selectCourse = async () => {
      visible.value = true
    }
    const closeCourseListModal = async () => {
      visible.value = false
    }
    const onCheckAllChange = async (e: any) => {
      const aCourse = []
      for (const course of availableCourse.value) {
        aCourse.push(course.value)
      }
      Object.assign(state, {
        selectedCourse: e.target.checked ? aCourse : [],
        indeterminate: false
      })
    }
    watch(() => state.selectedCourse, (val) => {
      state.indeterminate = !!val.length && val.length < availableCourse.value.length
      state.selectAllCourse = val.length === availableCourse.value.length
    })
    const importCandidates = async () => {
      try {
        loading.value = true
        const clientId = commonServices.getClientId()
        const data = {
          candidates: candidateData.value,
          courses: state.selectedCourse
        }
        await profileService.importCandidates(data, clientId)
        notify('Success', 'Candidates Imported Successfully', 'success')
        closeCourseListModal()
        router.push(`/client/candidates${loginAs.value}`)
        loading.value = false
        candidateData.value = []
        disableCreate.value = true
      } catch (error) {
        console.log('error', error)
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const showDeleteConfirm = async (profile, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Do you really want to remove this record?. This process cannot be undo.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        class: 'delte-confirm-modal',
        async onOk () {
          candidateData.value.splice(index, 1)
          const error = candidateData.value.filter(data => data.exist === true)
          if (error.length > 0) {
            disableCreate.value = true
          } else {
            disableCreate.value = false
          }
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    onMounted(() => {
      console.log('mounted')
      getUnassignedCourses()
    })
    return {
      clientId,
      handleChange,
      fileList: ref([]),
      headers,
      imageUploadUrl,
      columns,
      candidateData,
      showDeleteConfirm,
      disableCreate,
      importCandidates,
      loading,
      loginAs,
      selectCourse,
      onCheckAllChange,
      closeCourseListModal,
      availableCourse,
      visible,
      ...toRefs(state)
    }
  }
})
